import React, { useState } from "react";
import { ProductsSectionQuery } from "./Query";
import Img from 'gatsby-image';
import Slider from "react-slick";
import ArrowSlider from './../../../../svg/SliderArrow';
let load = false;
const Products = props => {
    const { nodes } = ProductsSectionQuery();
    const sectionId = props.sectionId;
    let data = [];
    let sidebarItems = [];
    let activeProducts = [];
    const [ activeCategory, setActiveCategory ] = useState('wytrawne');

    nodes.map(el => { return el.id === sectionId ? (data = [...data, el]) : null; });

    function getUnique(arr, comp) {

      const unique = arr
        .map(e => e[comp])
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter(e => arr[e]).map(e => arr[e]);
    
       return unique;
    }

    data[0].products_list.map(item => {
      item.acf.category.map(cat => {
        sidebarItems = [...sidebarItems, {
          name: cat.name,
          slug: cat.slug
        }]
      })
    })

    data[0].products_list.map(item => {
      item.acf.category.map(cat => {
        if(cat.slug === activeCategory) {
          activeProducts = [...activeProducts, {
            category: item.acf.category,
            editor: item.acf.editor,
            icons: item.acf.icons,
            bootle_image: item.acf.bootle_image,
          }]
        }
      })
    })
    
    const afterChange = (newIndex) => {
      if (!load) {
        const item = document.getElementsByClassName('first');
        item[0].classList.remove('first');
        load = true;
      }
      const items = document.getElementsByClassName('slick-slide');
      for(let i=0; i<items.length;i++) {
        let dataIndex = items[i].getAttribute('data-index');
        if(dataIndex == newIndex) {
          items[i].classList.add('animate')
          console.log(items[i].classList)
        }
      }
    }

    // const showSlider = () => {
    //   const items = document.getElementsByClassName('slick-slide');
    //   console.log(items)
    //   for(let i=0; i<items.length;i++) {
    //     let dataIndex = items[i].getAttribute('data-index');
    //     if(dataIndex == 0) {
    //       items[i].classList.add('load-animate')
    //       console.log(items[i].classList)
    //     }
    //   }
    // }
    // showSlider();


    const sliderSettings = {
      dots: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      className: 'first',
      arrows: true,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 2000,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      afterChange: afterChange,
      onLazyLoad: function() {
        console.log('ok')
      },
      // onInit: function() {
      //   const items = document.getElementsByClassName('slick-slide');
      //   console.log(items);
      //   for(let i=0; i<items.length;i++) {
      //     let dataIndex = items[i].getAttribute('data-index');
      //     if(dataIndex == 0) {
      //       items[i].classList.add('animate')
      //       console.log(items[i].classList)
      //     }
      //   }
      // },
    };

    function NextArrow(props) {
      const { className, style, onClick } = props;
      // document.querySelector('.slick-active .editor').addClass('test');
      return (
        <div className={className + " nextArrow"}  onClick={onClick} >
          
            <ArrowSlider />
            <div className="line"></div>
        </div>
      );
    }
    
    function PrevArrow(props) {
      const { className, style, onClick } = props;
      return (
          <div className={className + " prevArrow"}  onClick={onClick} >
              <ArrowSlider />
              <div className="line"></div>
          </div>
      );
    }

    let uniqueSidebar = getUnique(sidebarItems, 'name');
    let threeSide = uniqueSidebar[3];
    uniqueSidebar[3] = uniqueSidebar[2];
    uniqueSidebar[2] = threeSide;

    return (
        <section className="products-section">
          <h2>{ data[0].header }</h2>
          <div className="product-slider">
            <Slider { ...sliderSettings }>
              {activeProducts && activeProducts.map((item, index) => (
                <>
                <div className="menu-sidebar">
                  {uniqueSidebar.map((item, index) => (
                    <span className={ activeCategory == item.slug ? "active" : ""} onClick={ () => setActiveCategory(item.slug) } key={ index }>{ item.name }</span>
                  ))}
                </div>
                <div className="product-item">
                  {item.editor &&
                    <div className="editor" dangerouslySetInnerHTML={ { __html: item.editor }  } />
                  }
                  <div className="icons">
                    {item.icons && item.icons.map((item, index) => (
                      <>
                      <Img fluid={ item.icon.localFile.childImageSharp.fluid } alt={ item.icon.alt_text } />
                      </>
                    ))}
                  </div>
                </div>
                <div className="pagination">
                  <span> <p className="actual-number">{ ++index }</p> <p className="max-number">{ '/' + activeProducts.length }</p> </span>
                </div>
                </>
              ))}
            </Slider>
          </div>
        </section>
    )
}

export default Products;