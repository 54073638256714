import React, { Component } from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps"
import MapStyles from "./MapStyles.json";

const MapWithAMarker = withScriptjs(withGoogleMap(props =>
  <GoogleMap
    defaultZoom={13}
    defaultCenter={{ lat: parseFloat(props.centerMap.lat), lng: parseFloat(props.centerMap.lng) }}
    center={{ lat: parseFloat(props.centerMap.lat), lng: parseFloat(props.centerMap.lng) }}
    defaultOptions={{ styles: MapStyles }}
  >
    {props.shops.map((shop, index) => (
    <Marker
      key={index}
      position={{
         lat: parseFloat(shop.acf.lat), 
         lng: parseFloat(shop.acf.long) 
      }}
      icon={{ 
        url: props.icon,
        scaledSize: new window.google.maps.Size(26, 37)
      }}
      // onClick={ () => console.log(shop.acf) }
    />
    ))}
  </GoogleMap>
));

class MapSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      resultSearch: null
    }
  }

    async componentDidUpdate(prevProps, prevState) {
      let copyShops = this.props.shops;
      let searchedItems = [];
      if(prevProps.inputValue != this.props.inputValue && this.props.inputValue) {
        let val = this.props.inputValue.toLowerCase();
        if(val.length >= 3) {
            this.setState({
              resultSearch: null,
            })
            copyShops.map(city => {
                let dataCity = city.acf.city.toLowerCase();
                this.setState({
                  resultSearch: ''
                })
                if ( dataCity.indexOf(val) >= 0 ) {
                  searchedItems = [ ...searchedItems, city.acf]
                } 
            });  
            this.props.resultItems(searchedItems);
        }else{
          this.setState({
            resultSearch: null,
          })
        }
      }
    }
  
    render() {
      return (
        <MapWithAMarker
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB5atG-WX-6r4L4_4ZqA3KLwKjSycHYqek&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `580px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          shops={ this.props.shops }
          icon={ this.props.icon }
          centerMap={ this.props.centerMap }
        />
      )
    }
}

export default MapSettings;